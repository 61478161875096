<template>
    <div 
        class="comment" 
        :class="[myComment && 'my', item.newComment && 'new', item.shaking && 'shaking', isMobile && 'mobile']">
        <div 
            ref="commentItem" 
            class="comment_item">
            <Profiler 
                :user="item.author"
                showAvatar
                :class="myComment ? `ml-${isMobile ? 1 : 3}` : `mr-${isMobile ? 1 : 3}`"
                :getPopupContainer="getPopupContainer"
                :showUserName="false"
                initStatus />
            <div class="comment_item__body" :class="isMobile && 'select-none'">
                <div class="flex" :class="myComment && 'flex-row-reverse'">
                    <div>
                        <div 
                            class="flex" 
                            :class="myComment && 'justify-end'">
                            <a-spin :spinning="loading">
                                <a-dropdown 
                                    :trigger="isMobile ? [] : ['contextmenu']"
                                    :getPopupContainer="getPopupContainer">
                                    <div 
                                        class="body_wrap" 
                                        v-touch:longtap="longtapHandler"
                                        v-element-visibility="onElementVisibility">
                                        <div :class="myComment && 'flex justify-end'">
                                            <Profiler 
                                                :user="item.author"
                                                :getPopupContainer="getPopupContainer"
                                                nameClass="font-semibold truncate text-xs"
                                                :showAvatar="false" />
                                        </div>
                                        <template v-if="item.parent" >
                                            <div 
                                                v-if="item.parent.attachments && item.parent.attachments.length" 
                                                class="res_comment">
                                                <Profiler 
                                                    :user="item.parent.author"
                                                    :avatarSize="22"
                                                    initStatus
                                                    :getPopupContainer="getPopupContainer"
                                                    nameClass="font-semibold truncate text-xs"
                                                    @click="openComment(item.parent.id, item.id)" />
                                                <div @click="openComment(item.parent.id, item.id)">
                                                    <TextViewer 
                                                        v-if="item.parent.text"
                                                        :body="item.parent.text"/>
                                                </div>
                                                <div
                                                    ref="lght_wrap_parent"
                                                    class="comment_files mt-2">
                                                    <CommentFile 
                                                        v-for="file in item.parent.attachments" 
                                                        :key="file.id" 
                                                        :file="file"
                                                        :id="item.id" />
                                                </div>
                                            </div>
                                            <div 
                                                v-else 
                                                class="res_comment" 
                                                @click="openComment(item.parent.id, item.id)">
                                                <Profiler 
                                                    :user="item.parent.author"
                                                    :avatarSize="22"
                                                    initStatus
                                                    :getPopupContainer="getPopupContainer"
                                                    nameClass="font-semibold truncate text-xs" />
                                                <TextViewer 
                                                    v-if="item.parent.text"
                                                    :body="item.parent.text"/>
                                            </div>
                                        </template>
                                        <TextViewer 
                                            v-if="item.text"
                                            class="comment_text"
                                            :body="item.text" />
                                        <div 
                                            v-if="item.attachments && item.attachments.length"
                                            ref="lght_wrap"
                                            :class="item.text && 'mt-2'"
                                            class="comment_files">
                                            <CommentFile 
                                                v-for="file in item.attachments" 
                                                :key="file.id" 
                                                :file="file"
                                                :id="item.id" />
                                        </div>
                                        <div class="flex items-baseline mt-1" :class="myComment && 'justify-end'">
                                            <div class="font-light">
                                                {{ date }}
                                            </div>
                                            <transition name="slide-fade">
                                                <div v-if="item.is_updated" class="comment_updated">
                                                    {{ $t('comment.updated') }}
                                                </div>
                                            </transition>
                                        </div>
                                    </div>
                                    <a-menu v-if="!isMobile" slot="overlay">
                                        <a-menu-item v-if="reply" key="reply" class="flex items-center" @click="openResponseForm()">
                                            <i class="fi fi-rr-undo mr-2" />
                                            {{ $t('comment.reply') }}
                                        </a-menu-item>
                                        <a-menu-item v-if="user.id === item.author.id" key="edit" class="flex items-center" @click="openEdit()">
                                            <i class="fi fi-rr-edit mr-2" />
                                            {{ $t('comment.edit') }}
                                        </a-menu-item>
                                        <a-menu-item v-if="openCheck" key="open" class="flex items-center" @click="openComment(item.id)">
                                            <i class="fi fi-rr-comment-alt-dots mr-2" />
                                            {{ $t('comment.open') }}
                                        </a-menu-item>
                                        <a-menu-item v-if="addTaskCheck" key="task" class="flex items-center" @click="addTask()">
                                            <i class="fi fi-rr-list-check mr-2" />
                                            {{ $t('comment.add_task') }}
                                        </a-menu-item>
                                        <a-menu-item v-if="shareCheck" key="share" class="flex items-center" @click="share()">
                                            <i class="fi fi-rr-share mr-2" />
                                            {{ $t('comment.share') }}
                                        </a-menu-item>
                                        <template v-if="user.id === item.author.id">
                                            <a-menu-divider />
                                            <a-menu-item 
                                                key="delete" 
                                                class="flex items-center text-red-500" 
                                                @click="removeHandler()">
                                                <i class="fi fi-rr-trash mr-2" />
                                                {{ $t('comment.delete') }}
                                            </a-menu-item>
                                        </template>
                                    </a-menu>
                                </a-dropdown>
                            </a-spin>
                        </div>
                        <transition name="slide">
                            <div 
                                v-if="reply && !responseForm" 
                                class="body_actions" 
                                :class="myComment && 'justify-end'">
                                <div 
                                    class="act_btn" 
                                    @click="openResponseForm()">
                                    {{ $t('comment.reply') }}
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="dummy_body" :class="myComment ? 'mr-3' : 'ml-3'"></div>
                </div>
            </div>
        </div>
        <transition name="slide">
            <div v-if="responseForm || editData" class="res_form">
                <div class="res_form__label gray ml-2">
                    <template v-if="responseForm">
                        <i class="fi fi-rr-undo mr-2" />
                        {{ $t('comment.reply_to') }}
                    </template>
                    <template v-if="editData">
                        <i class="fi fi-rr-edit mr-2" />
                        {{ $t('comment.edit_comment') }}
                    </template>
                </div>
                <CommentInput
                    :related_object="related_object"
                    :model="model"
                    :modal="modal"
                    :showUsers="showUsers"
                    :editData="editData"
                    :getModalContainer="getModalContainer"
                    :closeEditorFunc="closeEditorFunc"
                    :blockLeft="blockLeft"
                    :showEmoji="showEmoji"
                    :setBlockLeft="setBlockLeft"
                    :pushNewComment="pushNewComment"
                    :updateComment="updateComment"
                    :showFileUpload="showFileUpload"
                    :parent="item" />
            </div>
        </transition>
        <component 
            :is="actionComponents" 
            ref="comment_actions"
            :openResponseForm="openResponseForm"
            :openEdit="openEdit"
            :openComment="openComment"
            :addTask="addTask"
            :share="share"
            :shareCheck="shareCheck"
            :addTaskCheck="addTaskCheck"
            :openCheck="openCheck"
            :user="user"
            :removeHandler="removeHandler"
            :reply="reply"
            :item="item" />
    </div>
</template>

<script>
import CommentInput from './CommentInput'
import CommentFile from './CommentFIle'
import eventBus from '@/utils/eventBus'
import TextViewer from '@apps/CKEditor/TextViewer.vue'
import eventBusLoc from './eventBus.js'
import { vElementVisibility } from '@vueuse/components'
export default {
    components: {
        CommentInput,
        CommentFile,
        TextViewer
    },
    directives: {
        ElementVisibility: vElementVisibility
    },
    props: {
        bodySelector: {
            type: String,
            default: 'body'
        },
        item: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        related_object: {
            type: [String, Number],
            required: true
        },
        model: {
            type: String,
            required: false
        },
        parent: {
            type: Object,
            default: null
        },
        extendDrawerZIndex: {
            type: Number,
            default: 1000
        },
        showUsers: {
            type: Boolean,
            default: true
        },
        showEmoji: {
            type: Boolean,
            default: true
        },
        showFileUpload: {
            type: Boolean,
            default: true
        },
        addTaskCheck: {
            type: Boolean,
            default: true
        },
        shareCheck: {
            type: Boolean,
            default: true
        },
        pushNewComment: {
            type: Function,
            default: () => {}
        },
        updateComment: {
            type: Function,
            default: () => {}
        },
        deleteComment: {
            type: Function,
            default: () => {}
        },
        modal: {
            type: Boolean,
            default: false
        },
        reply: {
            type: Boolean,
            default: true
        },
        openCheck: {
            type: Boolean,
            default: true
        },
        updateNewComment: {
            type: Function,
            default: () => {}
        },
        toggleNewCommentCount: {
            type: Function,
            default: () => {}
        },
        getModalContainer: {
            type: Function,
            default: () => document.body
        },
        closeMainInput: {
            type: Function,
            default: () => {}
        },
        setBlockLeft: {
            type: Function,
            default: () => {}
        },
        blockLeft: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        date() {
            return this.$moment(this.item.created_at).format('HH:mm')
        },
        myComment() {
            if(!this.modal && !this.blockLeft)
                return this.user.id === this.item.author.id
            return false
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        actionComponents() {
            if(this.isMobile)
                return () => import('./MobileMenuActions.vue')
            return null
        }
    },
    data() {
        return {
            showCommentForm: false,
            loading: false,
            showAnswer: false,
            responseForm: false,
            state: false,
            editData: null
        }
    },
    created () {
        if(this.item.newComment) {
            setTimeout(() => {
                this.updateNewComment(this.item.id)
            }, 1000)
        }
    },
    methods: {
        longtapHandler() {
            if(this.isMobile) {
                this.$nextTick(() => {
                    this.$refs.comment_actions.openDrawer()
                })
            }
        },
        openEdit() {
            this.closeMainInput()
            this.responseForm = false
            this.editData = this.item
        },
        onElementVisibility(e) {
            if(this.item.newVisible) {
                if(e) {
                    this.toggleNewCommentCount('min', this.item.id)
                } else {
                    this.toggleNewCommentCount('add')
                }
            }
            this.state = e
        },
        openComment(id, cid = null) {
            if(!this.modal)
                this.closeMainInput()
            eventBusLoc.$emit(`open_comment_detail_${this.related_object}`, id, cid)
        },
        getPopupContainer() {
            return this.$refs.commentItem
        },
        openResponseForm() {
            eventBus.$emit('CLOSE_RES_FORM')
            this.closeMainInput()
            this.editData = null
            this.responseForm = true
        },
        closeEditorFunc() {
            this.responseForm = false
            this.editData = null
        },
        share() {
            this.$store.commit('share/SET_SHARE_PARAMS', {
                model: 'comments',
                shareId: this.item.id,
                object: this.item,
                bodySelector: this.bodySelector
            })
        },
        addTask() {
            this.$store.commit('task/SET_TASK_DRAWER_ZINDEX', this.extendDrawerZIndex+100)

            let query = Object.assign({}, this.$route.query)

            if(query && query.task) {
                this.$store.commit('task/CHANGE_TASK_SHOW', false)
                delete query.task
                //await this.$router.push({query})
            }

            let form = {
                name: this.item.text_clear,
                operator: this.item.author,
                reason_model: 'comments',
                reason_id: this.item.id
            }
            this.$store.commit('task/SET_TASK_TYPE', 'task')
            eventBus.$emit('ADD_WATCH', {type: 'add_task', data: form})
        },
        async removeHandler() {
            try {
                this.loading = true
                await this.deleteComment(this.item.id)
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    },
    mounted() {
        eventBus.$on('CLOSE_RES_FORM', () => {
            this.responseForm = false
        })
        this.$nextTick(() => {
            if(this.item.attachments?.length) {
                const lightboxWrap = this.$refs[`lght_wrap`],
                    lightbox = lightboxWrap.querySelectorAll('.lht_l')
                if(lightbox?.length) {
                    lightGallery(lightboxWrap, {
                        selector: ".lht_l",
                        thumbnail: true,
                        rotateLeft: true,
                        rotateRight: true,
                        flipHorizontal: false,
                        flipVertical: false,
                        fullScreen: true,
                        animateThumb: true,
                        showThumbByDefault: true,
                        download: true,
                        speed: 300
                    })
                }
            }
            if(this.item.parent?.attachments?.length) {
                const lightboxWrap = this.$refs[`lght_wrap_parent`],
                    lightbox = lightboxWrap.querySelectorAll('.lht_l')
                if(lightbox?.length) {
                    lightGallery(lightboxWrap, {
                        selector: ".lht_l",
                        thumbnail: true,
                        rotateLeft: true,
                        rotateRight: true,
                        flipHorizontal: false,
                        flipVertical: false,
                        fullScreen: true,
                        animateThumb: true,
                        showThumbByDefault: true,
                        download: true,
                        speed: 300
                    })
                }
            }
        })
    },
    beforeDestroy() {
        eventBus.$off('CLOSE_RES_FORM')
    }
}
</script>

<style lang="scss" scoped>
.dummy_body{
    min-width: 32px;
}
.comment{
    &:not(:last-child){
        margin-bottom: 18px;
    }
    &.mobile{
        .body_wrap{
            transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
            &.touch{
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                transform: scale(0.97);
            }
        }
    }
    .res_form{
        &__label{
            margin-bottom: 5px;
            display: flex;
            align-items: center;
        }
    }
    &.my{
        .comment_item{
            flex-direction: row-reverse;
        }
    }
    &.new{
        .body_wrap{
            background: #edece0;
        }
    }
    .comment_updated{
        font-size: 10px;
        margin-left: 8px;
        color: var(--gray);
    }
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to{
        transform: translateX(10px);
        opacity: 0;
    }
    .slide-enter-active {
        -moz-transition-duration: 0.2s;
        -webkit-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }
    .slide-leave-active {
        -moz-transition-duration: 0.2s;
        -webkit-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    .slide-enter-to,
    .slide-leave {
        max-height: 100px;
        overflow: hidden;
    }
    .slide-enter,
    .slide-leave-to {
        overflow: hidden;
        max-height: 0;
    }
    &.shaking{
        .body_wrap{
            animation-fill-mode: both;
            animation-duration: 0.7s;
            animation-name: shakeX;
            animation-delay: 0s;
            transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
}
.comment_item{
    display: flex;
    align-items: flex-start;
    &__body{
        width: 100%;
        .body_actions{
            display: flex;
            padding: 0 15px;
            .act_btn{
                cursor: pointer;
                color: var(--gray);
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                &:hover{
                    color: var(--blue);
                }
            }
        }
        .body_wrap{
            border-radius: 15px;
            background: #eef2f5;
            padding: 10px 15px;
            margin-bottom: 8px;
            transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
            .comment_text{
                word-break: break-word;
                &::v-deep{
                    pre{
                        white-space: pre-wrap;
                    }
                    figure{
                        &.media{
                            width: 250px;
                        }
                    }
                }
            }
            .res_comment{
                background: var(--primaryHover);
                border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
                margin-bottom: 10px;
                padding: 10px;
                border-left: 4px solid var(--blue);
                cursor: pointer;
                word-break: break-word;
                &::v-deep{
                    .ck_text_viewer{
                        pre{
                            white-space: pre-wrap;
                        }
                        figure{
                            &.media{
                                width: 250px;
                            }
                        }
                    }
                }
            }
            .comment_files{
                display: flex;
                flex-wrap: wrap;
            }
            &::v-deep{
                .user_profile{
                    margin-bottom: 6px;
                    color: #000000;
                }
            }
        }
    }
}
@keyframes shakeX {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-3px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(3px, 0, 0);
  }
}
</style>